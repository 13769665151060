import { UpdateTitleInput, TitleStats, TitleInformation } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

export const getMyTitleStats = async (): Promise<TitleStats[]> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.MY_TITLE_STATS,
  })
  return res.data
}

export const createTitle = async (data: {
  displayName: string
  genreIds: number[]
}): Promise<{ titleId: number }> => {
  const res = await axios({
    method: 'POST',
    url: endpoints.TITLE,
    data,
  })
  return res.data
}

export const getTitleById = async (titleId: number): Promise<TitleInformation> => {
  const res = await axios({
    method: 'GET',
    url: `${endpoints.TITLE}/${titleId}`,
  })
  return res.data
}

export const updateTitleById = async (data: UpdateTitleInput): Promise<unknown> => {
  const { titleId, ...rest } = data
  const res = await axios({
    method: 'PUT',
    url: `${endpoints.TITLE}/${titleId}`,
    data: rest,
  })
  return res.data
}

export const deleteTitleById = async (titleId: number): Promise<unknown> => {
  const res = await axios({
    method: 'DELETE',
    url: `${endpoints.TITLE}/${titleId}`,
  })
  return res.data
}

export const publishTitleById = async (titleId: number): Promise<unknown> => {
  const res = await axios({
    method: 'POST',
    url: `${endpoints.TITLE}/${titleId}/publish`,
  })
  return res.data
}

export const unpublishTitleById = async (titleId: number): Promise<unknown> => {
  const res = await axios({
    method: 'POST',
    url: `${endpoints.TITLE}/${titleId}/unpublish`,
  })
  return res.data
}
