/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 11
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/kadokado/KadoKado_Prod/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'kadokado_prod';

export const ApiKey: Record<Environment, string> = {
  kadokado_prod: '50d1d44845e49a542d9bf6c882e41810'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '11',
    branch: 'main',
    source: 'web',
    versionId: 'a9e25cef-676c-4bb2-9e9e-284f3a397a2c'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * **用戶開啟的產品平台**
   *
   * * **網頁版(手機或桌機)：Web**
   *
   * * **app android：Android**
   *
   * * **app ios：iOS**
   */
  "device platform"?: string;
  "member email"?: string;
  "member kind"?: string;
  "member status"?: string;
}

export interface ApplyWithdrawalReviewButtonClickedProperties {
  /**
   * **收益請款 - 申請請款審核（請款金額）**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "withdrawal amount": number;
}

export interface BookAllButtonClickedProperties {
  /**
   * * **導覽列：Navbar - 我的作品**
   *
   * * **總覽：總覽 - 擁有章節數**
   *
   * * **側邊欄：sidebar - 我的作品**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 導覽列, 總覽, 側邊攔 |
   */
  "all button location": "導覽列" | "總覽" | "側邊攔";
}

export interface CallToActionClickedProperties {
  "call to action name": string;
  "call to action type": string;
}

export interface CategoryPageViewedProperties {
  /**
   * **The name of the category**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 繼續閱讀, 最新連載, 最新上架, 完結作品, KadoKado小說主題競賽, 讀角券推薦作品, 熱門排行-日榜, 熱門排行-週榜, 熱門排行-月榜, 熱門排行-暢銷週榜, 熱門排行-收藏週榜, 熱門排行-R18週榜, 猜你喜歡, カクヨム (KAKUYOMU), 魔法 i 戀島, 簽約作品-華文原創, 簽約作品-網路獨家, 主題參賽作品, 熱門排行-暢銷日榜, 熱門排行-暢銷月榜, 熱門排行-收藏日榜, 熱門排行-收藏月榜, 熱門排行-R18日榜, 熱門排行-R18月榜 |
   */
  "category name":
    | "繼續閱讀"
    | "最新連載"
    | "最新上架"
    | "完結作品"
    | "KadoKado小說主題競賽"
    | "讀角券推薦作品"
    | "熱門排行-日榜"
    | "熱門排行-週榜"
    | "熱門排行-月榜"
    | "熱門排行-暢銷週榜"
    | "熱門排行-收藏週榜"
    | "熱門排行-R18週榜"
    | "猜你喜歡"
    | "カクヨム (KAKUYOMU)"
    | "魔法 i 戀島"
    | "簽約作品-華文原創"
    | "簽約作品-網路獨家"
    | "主題參賽作品"
    | "熱門排行-暢銷日榜"
    | "熱門排行-暢銷月榜"
    | "熱門排行-收藏日榜"
    | "熱門排行-收藏月榜"
    | "熱門排行-R18日榜"
    | "熱門排行-R18月榜";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterClappedProperties {
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "clapped count": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterCommentPostedProperties {
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterCompletedProperties {
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterLockedWallEncounteredProperties {
  "chapter id"?: string;
  /**
   * This is an array of objects and each object contains:
   *
   * * chapter unlock type: ticket, point
   *
   * * chapter unlock period: 24h, 48, unlimited
   *
   * * chapter unlock cost: 1,2...,n (points OR tickets)
   *
   * Sample code for paywall:
   *
   * ```
   * [
   *     {"chapter unlock type" : "ticket", "chapter unlock period" : "24h", "chapter unlock cost": 2},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "unlimited", "chapter unlock cost": 24}
   * ]
   * ```
   *
   * Sample code for unlocking:
   *
   * ```
   * [
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10}
   * ]
   * ```
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  "chapter unlock record": {
    "chapter unlock cost"?: number;
    "chapter unlock period"?: "24h" | "48h" | "unlimited";
    "chapter unlock type"?: "points" | "ticket";
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterPublishedProperties {
  "chapter settings"?: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterReportedProperties {
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterSavedProperties {
  "chapter id"?: string;
  "chapter settings"?: any[];
  "stopping time"?: any;
  /**
   * 章節字數
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "word count"?: number;
}

export interface ChapterShareClickedProperties {
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterStartedProperties {
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterUnlockedProperties {
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * This is an array of objects and each object contains:
   *
   * * chapter unlock type: ticket, point
   *
   * * chapter unlock period: 24h, 48, unlimited
   *
   * * chapter unlock cost: 1,2...,n (points OR tickets)
   *
   * Sample code for paywall:
   *
   * ```
   * [
   *     {"chapter unlock type" : "ticket", "chapter unlock period" : "24h", "chapter unlock cost": 2},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "unlimited", "chapter unlock cost": 24}
   * ]
   * ```
   *
   * Sample code for unlocking:
   *
   * ```
   * [
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10}
   * ]
   * ```
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "chapter unlock record": string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
  "unlock method": string;
  "unlock period": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "unlock points used"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "unlock tickets used"?: number;
}

export interface CommentsButtonClickedProperties {
  /**
   * * **導覽列：Navbar - 留言管理**
   *
   * * **側邊欄：sidebar - 留言管理**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 導覽列, 側邊攔 |
   */
  "Comments button location": "導覽列" | "側邊攔";
}

export interface DataCenterButtonClickedProperties {
  /**
   * * **導覽列：Navbar - 數據中心**
   *
   * * **歷史閱覽數：總覽 - 歷史閱覽數**
   *
   * * **歷史收藏數：總覽 - 歷史收藏數**
   *
   * * **側邊欄：sidebar - 數據中心**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 導覽列, 歷史閱覽數, 歷史收藏數, 側邊欄 |
   */
  "data center button location": "導覽列" | "歷史閱覽數" | "歷史收藏數" | "側邊欄";
}

export interface ErrorEncounteredProperties {
  "error name": string;
  "error type": string;
}

export interface GiftClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | user page, book page, chapter page |
   */
  position: "user page" | "book page" | "chapter page";
}

export interface GiftSentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "gift price": number;
}

export interface LoggedOutProperties {
  /**
   * **用戶登出的情境-**
   *
   * * **多 domain (例如當前中國版，假設連不到任何 domain，為了避免他畫面卡住也有設計會幫他登出)(僅APP有)：multiple domain**
   *
   * * **用戶主動點擊登出按鈕：by user**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | multiple domain, by user |
   */
  "logout type": "multiple domain" | "by user";
}

export interface LoginWallEncounteredProperties {
  /**
   * * **kodopoints：在首頁點角點後跳登入 dialog**
   *
   * * **clap：點拍手後跳登入 dialog**
   *
   * * **favorite：點收藏後跳登入 dialog**
   *
   * * **comment：點留言後跳登入 dialog**
   *
   * * **purchase_chapter：點付費章節後跳登入 dialog**
   *
   * * **chapter_report：點章節檢舉後跳登入 dialog**
   *
   * * **gift：點送禮贊助後跳登入dialog**
   *
   * * **history：點我的小說頁後跳登入dialog**
   *
   * * **userpoints：點帳務中心後跳登入dialog**
   *
   * * **myreview：點年度回顧後跳登入dialog**
   *
   * * **announce_update：點我的通知>作品更新後跳登入dialog**
   *
   * * **announce_comment：點我的通知>留言通知後跳登入dialog**
   *
   * * **user：點個人設定頁後跳登入dialog**
   *
   * * **visitor_recommend_login_dialog：登入會員使用角點服務dialog(for ios)**
   *
   * * **visitor_buy_kpoint_dialog：未登入購買注意事項dialog(for ios)**
   *
   * * **visitor_account_center：點選「角角訪客」頭像後跳登入 dialog**
   *
   * * **visitor_favorite：點選收藏作品頁後跳登入 dialog**
   *
   * * **visitor_history：點選收藏->瀏覽紀錄頁後登入 dialog**
   *
   * * **visitor_comment：點選收藏->留言紀錄頁後登入 dialog**
   *
   * * **event_post：賽事討論區新增主題跳登入 dialog**
   *
   * * **event_reply：賽事討論區新增留言回應跳登入 dialog**
   *
   * * **ranking：排行榜頁點擊收藏後跳登入 dialog**
   *
   * * **visitor_subscription：訪客至訂閱管理頁面查看訂閱管理後跳登入dialog**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | kodopoints, clap, favorite, comment, purchase_chapter, chapter_report, gift, history, userpoints, myreview, announce_update, announce_comment, user, visitor_recommend_login_dialog, visitor_buy_kpoint_dialog, visitor_account_center, visitor_favorite, visitor_history, visitor_comment, event_post, event_reply, ranking, visitor_subscription |
   */
  "account creation popped up":
    | "kodopoints"
    | "clap"
    | "favorite"
    | "comment"
    | "purchase_chapter"
    | "chapter_report"
    | "gift"
    | "history"
    | "userpoints"
    | "myreview"
    | "announce_update"
    | "announce_comment"
    | "user"
    | "visitor_recommend_login_dialog"
    | "visitor_buy_kpoint_dialog"
    | "visitor_account_center"
    | "visitor_favorite"
    | "visitor_history"
    | "visitor_comment"
    | "event_post"
    | "event_reply"
    | "ranking"
    | "visitor_subscription";
}

export interface NovelFavoritedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels: any[];
  /**
   * * **novel：在作品頁點收藏**
   *
   * * **chapter：在章節頁點收藏**
   *
   * * **category：在作品集合頁點收藏（排行榜、主題參賽作品頁、讀角券推薦作品、猜你喜歡頁）**
   *
   * * **search：在搜尋頁點收藏**
   *
   * * **home_guess_like：首頁猜你喜歡**
   *
   * * **onboarding_category：onboarding 分類推薦收藏**
   *
   * * **onboarding_platform：onboarding 平台推薦收藏**
   *
   * * **onboarding_bottomcard：onboarding 進入作品圖卡收藏**
   *
   * * **ticket_recommend：在讀角券推薦專區進行收藏**
   *
   * * **history：在收藏中的瀏覽紀錄點擊收藏**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | novel, chapter, category, search, home_guess_like, onboarding_category, onboarding_platform, onboarding_bottomcard, history, ticket_recommend |
   */
  "position type":
    | "novel"
    | "chapter"
    | "category"
    | "search"
    | "home_guess_like"
    | "onboarding_category"
    | "onboarding_platform"
    | "onboarding_bottomcard"
    | "history"
    | "ticket_recommend";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface NovelPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface NovelShareClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface OnboardingStepCompletedProperties {
  /**
   * * selected_category_onboarding：有選標籤
   *
   * * next_category_onboarding：在標籤頁，點擊下一步
   *
   * * favorite_category_onboarding：選標籤後收藏作品
   *
   * * favorite_platform_onboarding：未選標籤，收藏平台推薦的作品
   *
   * * favorite_bottomcard_onboarding：點作品圖卡後收藏
   *
   * * next_finish_onboarding：在作品推薦頁，點擊下一步完成 onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | selected_category_onboarding, favorite_category_onboarding, next_category_onboarding, favorite_platform_onboarding, favorite_bottomcard_onboarding, next_finish_onboarding |
   */
  "onboarding step":
    | "selected_category_onboarding"
    | "favorite_category_onboarding"
    | "next_category_onboarding"
    | "favorite_platform_onboarding"
    | "favorite_bottomcard_onboarding"
    | "next_finish_onboarding";
}

export interface PointsAddedToCartProperties {
  /**
   * Users can purchase multiple points packages.
   *
   * This is an array of 'points package' objects, each object contains:
   *
   * * package original price
   *
   * * package net price
   *
   * * package discount
   *
   * * package total points
   *
   * * package quantity
   *
   * * package name
   *
   *
   *
   */
  "points packages": any[];
}

export interface PointsClaimedProperties {
  /**
   * Users can purchase multiple points packages.
   *
   * This is an array of 'points package' objects, each object contains:
   *
   * * package original price
   *
   * * package net price
   *
   * * package discount
   *
   * * package total points
   *
   * * package quantity
   *
   * * package name
   *
   *
   *
   */
  "points packages": any[];
}

export interface RankingCategoryClickedProperties {
  /**
   * * **排行榜的計算邏輯：熱門、收藏、暢銷、18禁
   *  排行榜的分類：BL、戀愛言情、百合、奇幻...等**
   *
   * * **排行榜的計算區間：日排行、周排行、月排行
   *
   *  ranking category：{排行榜的計算邏輯}+{排行榜的分類}+{排行榜的計算區間} (例如，ranking category：熱門-BL-日排行)**
   */
  "ranking category": string;
}

export interface RevenueReportButtonClickedProperties {
  /**
   * * **導覽列：Navbar - 收益請款**
   *
   * * **總覽：總覽 - 累積收益**
   *
   * * **側邊欄：sidebar - 收益請款**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 導覽列, 總覽, 側邊欄 |
   */
  "revenue report button location": "導覽列" | "總覽" | "側邊欄";
}

export interface SearchCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "search results count": number;
  "search term": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface SearchFilteredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "filter applied"?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "genre filter applied"?: string[];
  "sort applied"?: string;
}

export interface SearchResultClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels: {
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface TitlePublishedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface CallToActionProperties {
  "call to action name": string;
  "call to action type": string;
}

export interface ChapterDetailsProperties {
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
}

export interface ChapterUnlockProperties {
  /**
   * This is an array of objects and each object contains:
   *
   * * chapter unlock type: ticket, point
   *
   * * chapter unlock period: 24h, 48, unlimited
   *
   * * chapter unlock cost: 1,2...,n (points OR tickets)
   *
   * Sample code for paywall:
   *
   * ```
   * [
   *     {"chapter unlock type" : "ticket", "chapter unlock period" : "24h", "chapter unlock cost": 2},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "unlimited", "chapter unlock cost": 24}
   * ]
   * ```
   *
   * Sample code for unlocking:
   *
   * ```
   * [
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10}
   * ]
   * ```
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "chapter unlock record": string[];
  "unlock method": string;
  "unlock period": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "unlock points used"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "unlock tickets used"?: number;
}

export interface ErrorProperties {
  "error name": string;
  "error type": string;
}

export interface NovelDetailsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface OnboardingProperties {
  /**
   * * selected_category_onboarding：有選標籤
   *
   * * next_category_onboarding：在標籤頁，點擊下一步
   *
   * * favorite_category_onboarding：選標籤後收藏作品
   *
   * * favorite_platform_onboarding：未選標籤，收藏平台推薦的作品
   *
   * * favorite_bottomcard_onboarding：點作品圖卡後收藏
   *
   * * next_finish_onboarding：在作品推薦頁，點擊下一步完成 onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | selected_category_onboarding, favorite_category_onboarding, next_category_onboarding, favorite_platform_onboarding, favorite_bottomcard_onboarding, next_finish_onboarding |
   */
  "onboarding step":
    | "selected_category_onboarding"
    | "favorite_category_onboarding"
    | "next_category_onboarding"
    | "favorite_platform_onboarding"
    | "favorite_bottomcard_onboarding"
    | "next_finish_onboarding";
}

export interface PointsClaimingProperties {
  /**
   * Users can purchase multiple points packages.
   *
   * This is an array of 'points package' objects, each object contains:
   *
   * * package original price
   *
   * * package net price
   *
   * * package discount
   *
   * * package total points
   *
   * * package quantity
   *
   * * package name
   *
   *
   *
   */
  "points packages": any[];
}

export interface SearchProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "search results count": number;
  "search term": string;
}

export interface SearchFilterProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "filter applied"?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "genre filter applied"?: string[];
  "sort applied"?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreated implements BaseEvent {
  event_type = 'Account Created';
}

export class AccountCreationStarted implements BaseEvent {
  event_type = 'Account Creation Started';
}

export class AccountVerified implements BaseEvent {
  event_type = 'Account Verified';
}

export class ApplyWithdrawalReviewButtonClicked implements BaseEvent {
  event_type = 'Apply Withdrawal Review Button Clicked';

  constructor(
    public event_properties: ApplyWithdrawalReviewButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookAllButtonClicked implements BaseEvent {
  event_type = 'Book All Button Clicked';

  constructor(
    public event_properties: BookAllButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BottomRankingClicked implements BaseEvent {
  event_type = 'Bottom Ranking Clicked';
}

export class CallToActionClicked implements BaseEvent {
  event_type = 'Call To Action Clicked';

  constructor(
    public event_properties: CallToActionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelSubscriptionClicked implements BaseEvent {
  event_type = 'Cancel Subscription Clicked';
}

export class CategoryPageViewed implements BaseEvent {
  event_type = 'Category Page Viewed';

  constructor(
    public event_properties: CategoryPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterClapped implements BaseEvent {
  event_type = 'Chapter Clapped';

  constructor(
    public event_properties: ChapterClappedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterCommentPosted implements BaseEvent {
  event_type = 'Chapter Comment Posted';

  constructor(
    public event_properties: ChapterCommentPostedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterCompleted implements BaseEvent {
  event_type = 'Chapter Completed';

  constructor(
    public event_properties: ChapterCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterCreated implements BaseEvent {
  event_type = 'Chapter Created';

  constructor(
    public event_properties: ChapterCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterListsSubscriptionClicked implements BaseEvent {
  event_type = 'Chapter Lists Subscription Clicked';
}

export class ChapterLockedWallEncountered implements BaseEvent {
  event_type = 'Chapter Locked Wall Encountered';

  constructor(
    public event_properties: ChapterLockedWallEncounteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterPublished implements BaseEvent {
  event_type = 'Chapter Published';

  constructor(
    public event_properties: ChapterPublishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterReported implements BaseEvent {
  event_type = 'Chapter Reported';

  constructor(
    public event_properties: ChapterReportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterSaved implements BaseEvent {
  event_type = 'Chapter Saved';

  constructor(
    public event_properties?: ChapterSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterShareClicked implements BaseEvent {
  event_type = 'Chapter Share Clicked';

  constructor(
    public event_properties: ChapterShareClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterStarted implements BaseEvent {
  event_type = 'Chapter Started';

  constructor(
    public event_properties: ChapterStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterUnlocked implements BaseEvent {
  event_type = 'Chapter Unlocked';

  constructor(
    public event_properties: ChapterUnlockedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CommentsButtonClicked implements BaseEvent {
  event_type = 'Comments Button Clicked';

  constructor(
    public event_properties: CommentsButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContestAllButtonClicked implements BaseEvent {
  event_type = 'Contest All Button Clicked';
}

export class DataCenterButtonClicked implements BaseEvent {
  event_type = 'Data Center Button Clicked';

  constructor(
    public event_properties: DataCenterButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ErrorEncountered implements BaseEvent {
  event_type = 'Error Encountered';

  constructor(
    public event_properties: ErrorEncounteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GiftClicked implements BaseEvent {
  event_type = 'Gift  Clicked';

  constructor(
    public event_properties: GiftClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GiftSent implements BaseEvent {
  event_type = 'Gift Sent';

  constructor(
    public event_properties: GiftSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomePageViewed implements BaseEvent {
  event_type = 'Home Page Viewed';
}

export class LoggedIn implements BaseEvent {
  event_type = 'Logged In';
}

export class LoggedOut implements BaseEvent {
  event_type = 'Logged Out';

  constructor(
    public event_properties: LoggedOutProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginWallEncountered implements BaseEvent {
  event_type = 'Login Wall Encountered';

  constructor(
    public event_properties: LoginWallEncounteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NovelFavorited implements BaseEvent {
  event_type = 'Novel Favorited';

  constructor(
    public event_properties: NovelFavoritedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NovelPageViewed implements BaseEvent {
  event_type = 'Novel Page Viewed';

  constructor(
    public event_properties: NovelPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NovelShareClicked implements BaseEvent {
  event_type = 'Novel Share Clicked';

  constructor(
    public event_properties: NovelShareClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingStepCompleted implements BaseEvent {
  event_type = 'Onboarding Step Completed';

  constructor(
    public event_properties: OnboardingStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenAppToSubscriptionPlanClicked implements BaseEvent {
  event_type = 'Open App to Subscription Plan Clicked';
}

export class PointsAddedToCart implements BaseEvent {
  event_type = 'Points Added To Cart';

  constructor(
    public event_properties: PointsAddedToCartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PointsClaimed implements BaseEvent {
  event_type = 'Points Claimed';

  constructor(
    public event_properties: PointsClaimedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PostClicked implements BaseEvent {
  event_type = 'Post Clicked';
}

export class PurchaseGiftPointsClicked implements BaseEvent {
  event_type = 'Purchase Gift Points Clicked';
}

export class PurchaseSubscriptionPlanClicked implements BaseEvent {
  event_type = 'Purchase Subscription Plan Clicked';
}

export class RankingCategoryClicked implements BaseEvent {
  event_type = 'Ranking Category Clicked';

  constructor(
    public event_properties: RankingCategoryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RankingFilterClicked implements BaseEvent {
  event_type = 'Ranking Filter Clicked';
}

export class RenewSubscriptionClicked implements BaseEvent {
  event_type = 'Renew Subscription  Clicked';
}

export class ReplyClicked implements BaseEvent {
  event_type = 'Reply Clicked';
}

export class RevenueReportButtonClicked implements BaseEvent {
  event_type = 'Revenue Report Button Clicked';

  constructor(
    public event_properties: RevenueReportButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchCompleted implements BaseEvent {
  event_type = 'Search Completed';

  constructor(
    public event_properties: SearchCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchFiltered implements BaseEvent {
  event_type = 'Search Filtered';

  constructor(
    public event_properties?: SearchFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchResultClicked implements BaseEvent {
  event_type = 'Search Result Clicked';

  constructor(
    public event_properties: SearchResultClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionDialogViewed implements BaseEvent {
  event_type = 'Subscription Dialog Viewed';
}

export class SubscriptionFaqClicked implements BaseEvent {
  event_type = 'Subscription FAQ Clicked';
}

export class SubscriptionNovelsClicked implements BaseEvent {
  event_type = 'Subscription Novels Clicked';
}

export class SubscriptionPlanViewed implements BaseEvent {
  event_type = 'Subscription Plan Viewed';
}

export class SubscriptionStatePlanClicked implements BaseEvent {
  event_type = 'Subscription State Plan Clicked';
}

export class SubscriptionStateViewed implements BaseEvent {
  event_type = 'Subscription State Viewed';
}

export class SubscriptionSuccessViewed implements BaseEvent {
  event_type = 'Subscription Success Viewed';
}

export class TitlePublished implements BaseEvent {
  event_type = 'Title Published';

  constructor(
    public event_properties: TitlePublishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VerifiedBadgeButtonClicked implements BaseEvent {
  event_type = 'Verified Badge Button Clicked';
}

export class WithdrawalRecordButtonClicked implements BaseEvent {
  event_type = 'Withdrawal Record Button Clicked';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Account Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Account%20Created)
   *
   * This event tracks when a user creates a new account on our platform
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  accountCreated(
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(), options);
  }

  /**
   * Account Creation Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Account%20Creation%20Started)
   *
   * This event tracks the initiation of the account creation process for user accounts
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  accountCreationStarted(
    options?: EventOptions,
  ) {
    return this.track(new AccountCreationStarted(), options);
  }

  /**
   * Account Verified
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Account%20Verified)
   *
   * This event is triggered when a user's account has been successfully verified
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  accountVerified(
    options?: EventOptions,
  ) {
    return this.track(new AccountVerified(), options);
  }

  /**
   * Apply Withdrawal Review Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Apply%20Withdrawal%20Review%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 收益請款 - 申請請款審核**
   *
   * @param properties The event's properties (e.g. withdrawal amount)
   * @param options Amplitude event options.
   */
  applyWithdrawalReviewButtonClicked(
    properties: ApplyWithdrawalReviewButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplyWithdrawalReviewButtonClicked(properties), options);
  }

  /**
   * Book All Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Book%20All%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 我的作品／擁有章節數**
   *
   * @param properties The event's properties (e.g. all button location)
   * @param options Amplitude event options.
   */
  bookAllButtonClicked(
    properties: BookAllButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookAllButtonClicked(properties), options);
  }

  /**
   * Bottom Ranking Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Bottom%20Ranking%20Clicked)
   *
   * **當用戶點擊Bottom Navigation的排行icon時**
   *
   * @param options Amplitude event options.
   */
  bottomRankingClicked(
    options?: EventOptions,
  ) {
    return this.track(new BottomRankingClicked(), options);
  }

  /**
   * Call To Action Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Call%20To%20Action%20Clicked)
   *
   * TO BE SKIPPED
   *
   *
   * This event tracks when a user clicks on a call to action button within the navigation of our platform
   *
   *
   *
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. call to action name)
   * @param options Amplitude event options.
   */
  callToActionClicked(
    properties: CallToActionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CallToActionClicked(properties), options);
  }

  /**
   * Cancel Subscription Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Cancel%20Subscription%20Clicked)
   *
   * **點擊「仍要取消」Button**
   *
   * @param options Amplitude event options.
   */
  cancelSubscriptionClicked(
    options?: EventOptions,
  ) {
    return this.track(new CancelSubscriptionClicked(), options);
  }

  /**
   * Category Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Category%20Page%20Viewed)
   *
   * This event tracks when a user views a category page while navigating through the website
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. category name)
   * @param options Amplitude event options.
   */
  categoryPageViewed(
    properties: CategoryPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CategoryPageViewed(properties), options);
  }

  /**
   * Chapter Clapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Clapped)
   *
   * This event tracks when a user claps for a chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterClapped(
    properties: ChapterClappedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterClapped(properties), options);
  }

  /**
   * Chapter Comment Posted
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Comment%20Posted)
   *
   * This event is triggered when a user posts a comment on a chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterCommentPosted(
    properties: ChapterCommentPostedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterCommentPosted(properties), options);
  }

  /**
   * Chapter Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Completed)
   *
   * This event is triggered when a user completes a chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterCompleted(
    properties: ChapterCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterCompleted(properties), options);
  }

  /**
   * Chapter Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Created)
   *
   * **每次建立新章節**
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  chapterCreated(
    properties: ChapterCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterCreated(properties), options);
  }

  /**
   * Chapter Lists Subscription Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Lists%20Subscription%20Clicked)
   *
   * **當使用者點擊作品頁中的訂閱章節列表**
   *
   * @param options Amplitude event options.
   */
  chapterListsSubscriptionClicked(
    options?: EventOptions,
  ) {
    return this.track(new ChapterListsSubscriptionClicked(), options);
  }

  /**
   * Chapter Locked Wall Encountered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Locked%20Wall%20Encountered)
   *
   * This event tracks when a user encounters a locked wall while reading a chapter in the app
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterLockedWallEncountered(
    properties: ChapterLockedWallEncounteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterLockedWallEncountered(properties), options);
  }

  /**
   * Chapter Published
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Published)
   *
   * **章節上架**
   *
   * @param properties The event's properties (e.g. chapter settings)
   * @param options Amplitude event options.
   */
  chapterPublished(
    properties: ChapterPublishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterPublished(properties), options);
  }

  /**
   * Chapter Reported
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Reported)
   *
   * This event is triggered when a user reports a chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterReported(
    properties: ChapterReportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterReported(properties), options);
  }

  /**
   * Chapter Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Saved)
   *
   * **每次儲存文章（包含 autosave）**
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterSaved(
    properties?: ChapterSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterSaved(properties), options);
  }

  /**
   * Chapter Share Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Share%20Clicked)
   *
   * This event tracks when a user clicks on the "Share" button while reading a chapter
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterShareClicked(
    properties: ChapterShareClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterShareClicked(properties), options);
  }

  /**
   * Chapter Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Started)
   *
   * This event is triggered when a user starts reading a new chapter in a book or any other content. It helps track user engagement and progress within the content
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterStarted(
    properties: ChapterStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterStarted(properties), options);
  }

  /**
   * Chapter Unlocked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Unlocked)
   *
   * This event tracks when a user unlocks a new chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterUnlocked(
    properties: ChapterUnlockedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterUnlocked(properties), options);
  }

  /**
   * Comments Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Comments%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 留言管理**
   *
   * @param properties The event's properties (e.g. Comments button location)
   * @param options Amplitude event options.
   */
  commentsButtonClicked(
    properties: CommentsButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CommentsButtonClicked(properties), options);
  }

  /**
   * Contest All Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Contest%20All%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 徵文比賽投稿**
   *
   * @param options Amplitude event options.
   */
  contestAllButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new ContestAllButtonClicked(), options);
  }

  /**
   * Data Center Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Data%20Center%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 數據中心**
   *
   * @param properties The event's properties (e.g. data center button location)
   * @param options Amplitude event options.
   */
  dataCenterButtonClicked(
    properties: DataCenterButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataCenterButtonClicked(properties), options);
  }

  /**
   * Error Encountered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Error%20Encountered)
   *
   * This event tracks when an error is encountered during a user's interaction with our platform
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. error name)
   * @param options Amplitude event options.
   */
  errorEncountered(
    properties: ErrorEncounteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ErrorEncountered(properties), options);
  }

  /**
   * Gift  Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Gift%20%20Clicked)
   *
   * **當用戶點擊送禮跳出選購禮物視窗**
   *
   * @param properties The event's properties (e.g. position)
   * @param options Amplitude event options.
   */
  giftClicked(
    properties: GiftClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GiftClicked(properties), options);
  }

  /**
   * Gift Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Gift%20Sent)
   *
   * **在選購禮物視窗中點擊立即送禮(送禮成功)**
   *
   * @param properties The event's properties (e.g. gift price)
   * @param options Amplitude event options.
   */
  giftSent(
    properties: GiftSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new GiftSent(properties), options);
  }

  /**
   * Home Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Home%20Page%20Viewed)
   *
   * This event tracks when a user views the home page of our website. It provides insights into user navigation behavior and can be used to analyze engagement and user flow on the home page
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  homePageViewed(
    options?: EventOptions,
  ) {
    return this.track(new HomePageViewed(), options);
  }

  /**
   * Logged In
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Logged%20In)
   *
   * This event tracks when a user successfully logs into their account.
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  loggedIn(
    options?: EventOptions,
  ) {
    return this.track(new LoggedIn(), options);
  }

  /**
   * Logged Out
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Logged%20Out)
   *
   * This event tracks when a user logs out of their account
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. logout type)
   * @param options Amplitude event options.
   */
  loggedOut(
    properties: LoggedOutProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedOut(properties), options);
  }

  /**
   * Login Wall Encountered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Login%20Wall%20Encountered)
   *
   * This event tracks when a user encounters a login wall while trying to access content within the platform that requires an account but they aren't currently logged in. 
   *
   *
   * This includes:
   *
   * * Clicking on a locked chaper
   *
   * * Clicking on comment button
   *
   * * Clicking on like button
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. account creation popped up)
   * @param options Amplitude event options.
   */
  loginWallEncountered(
    properties: LoginWallEncounteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginWallEncountered(properties), options);
  }

  /**
   * Novel Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Novel%20Favorited)
   *
   * This event tracks when a user favorites a novel on the platform
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  novelFavorited(
    properties: NovelFavoritedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NovelFavorited(properties), options);
  }

  /**
   * Novel Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Novel%20Page%20Viewed)
   *
   * This event tracks when a user views a page related to novels on our platform
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  novelPageViewed(
    properties: NovelPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NovelPageViewed(properties), options);
  }

  /**
   * Novel Share Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Novel%20Share%20Clicked)
   *
   * This event tracks when a user clicks on the "Share" button for a specific novel, indicating their intention to share it with others
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  novelShareClicked(
    properties: NovelShareClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NovelShareClicked(properties), options);
  }

  /**
   * Onboarding Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Onboarding%20Step%20Completed)
   *
   * This event tracks the completion of a specific step in the onboarding process. It provides insights into user progress and engagement during the onboarding experience
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. onboarding step)
   * @param options Amplitude event options.
   */
  onboardingStepCompleted(
    properties: OnboardingStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStepCompleted(properties), options);
  }

  /**
   * Open App to Subscription Plan Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Open%20App%20to%20Subscription%20Plan%20Clicked)
   *
   * **點擊「開啟 App 前往訂閱」Button**
   *
   * @param options Amplitude event options.
   */
  openAppToSubscriptionPlanClicked(
    options?: EventOptions,
  ) {
    return this.track(new OpenAppToSubscriptionPlanClicked(), options);
  }

  /**
   * Points Added To Cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Points%20Added%20To%20Cart)
   *
   * This event tracks the number of points that are added to the cart during a points purchase transaction
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. points packages)
   * @param options Amplitude event options.
   */
  pointsAddedToCart(
    properties: PointsAddedToCartProperties,
    options?: EventOptions,
  ) {
    return this.track(new PointsAddedToCart(properties), options);
  }

  /**
   * Points Claimed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Points%20Claimed)
   *
   * This event tracks when users submits their points serial number to claim a purchased points package
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. points packages)
   * @param options Amplitude event options.
   */
  pointsClaimed(
    properties: PointsClaimedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PointsClaimed(properties), options);
  }

  /**
   * Post Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Post%20Clicked)
   *
   * **當用戶在賽事頁送出「新增主題」**
   *
   * @param options Amplitude event options.
   */
  postClicked(
    options?: EventOptions,
  ) {
    return this.track(new PostClicked(), options);
  }

  /**
   * Purchase Gift Points Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Purchase%20Gift%20Points%20Clicked)
   *
   * **當用戶點擊"$購買角點"button**
   *
   * @param options Amplitude event options.
   */
  purchaseGiftPointsClicked(
    options?: EventOptions,
  ) {
    return this.track(new PurchaseGiftPointsClicked(), options);
  }

  /**
   * Purchase Subscription Plan Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Purchase%20Subscription%20Plan%20Clicked)
   *
   * **點擊「立即訂閱」Button**
   *
   * @param options Amplitude event options.
   */
  purchaseSubscriptionPlanClicked(
    options?: EventOptions,
  ) {
    return this.track(new PurchaseSubscriptionPlanClicked(), options);
  }

  /**
   * Ranking Category Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Ranking%20Category%20Clicked)
   *
   * **點選各類別的排行榜**
   *
   * @param properties The event's properties (e.g. ranking category)
   * @param options Amplitude event options.
   */
  rankingCategoryClicked(
    properties: RankingCategoryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RankingCategoryClicked(properties), options);
  }

  /**
   * Ranking Filter Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Ranking%20Filter%20Clicked)
   *
   * **點選排行榜篩選器**
   *
   * @param options Amplitude event options.
   */
  rankingFilterClicked(
    options?: EventOptions,
  ) {
    return this.track(new RankingFilterClicked(), options);
  }

  /**
   * Renew Subscription  Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Renew%20Subscription%20%20Clicked)
   *
   * **點擊「保持訂閱」Button**
   *
   * @param options Amplitude event options.
   */
  renewSubscriptionClicked(
    options?: EventOptions,
  ) {
    return this.track(new RenewSubscriptionClicked(), options);
  }

  /**
   * Reply Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Reply%20Clicked)
   *
   * **當用戶在賽事頁送出「新增回應」**
   *
   * @param options Amplitude event options.
   */
  replyClicked(
    options?: EventOptions,
  ) {
    return this.track(new ReplyClicked(), options);
  }

  /**
   * Revenue Report Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Revenue%20Report%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 收益請款／累積收益**
   *
   * @param properties The event's properties (e.g. revenue report button location)
   * @param options Amplitude event options.
   */
  revenueReportButtonClicked(
    properties: RevenueReportButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueReportButtonClicked(properties), options);
  }

  /**
   * Search Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Search%20Completed)
   *
   * This event is triggered when a user completes a search on the platform. It provides valuable data on user search behavior and can be used to analyze search trends, popular search terms, and search effectiveness
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  searchCompleted(
    properties: SearchCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchCompleted(properties), options);
  }

  /**
   * Search Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Search%20Filtered)
   *
   * This event tracks when a user applies a filter to their search query. It provides insights into the specific filters users are using to refine their search results
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. filter applied)
   * @param options Amplitude event options.
   */
  searchFiltered(
    properties?: SearchFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchFiltered(properties), options);
  }

  /**
   * Search Result Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Search%20Result%20Clicked)
   *
   * This event tracks when a user clicks on a search result after performing a search
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  searchResultClicked(
    properties: SearchResultClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultClicked(properties), options);
  }

  /**
   * Subscription Dialog Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20Dialog%20Viewed)
   *
   * **跳出「訂閱限定提示」Dialog**
   *
   * @param options Amplitude event options.
   */
  subscriptionDialogViewed(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionDialogViewed(), options);
  }

  /**
   * Subscription FAQ Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20FAQ%20Clicked)
   *
   * **點擊「FAQ」Button**
   *
   * @param options Amplitude event options.
   */
  subscriptionFaqClicked(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionFaqClicked(), options);
  }

  /**
   * Subscription Novels Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20Novels%20Clicked)
   *
   * **點擊「查看訂閱作品系列」Button**
   *
   * @param options Amplitude event options.
   */
  subscriptionNovelsClicked(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionNovelsClicked(), options);
  }

  /**
   * Subscription Plan Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20Plan%20Viewed)
   *
   * **瀏覽訂閱方案頁**
   *
   * @param options Amplitude event options.
   */
  subscriptionPlanViewed(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionPlanViewed(), options);
  }

  /**
   * Subscription State Plan Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20State%20Plan%20Clicked)
   *
   * **點擊「查看方案」Button**
   *
   * @param options Amplitude event options.
   */
  subscriptionStatePlanClicked(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionStatePlanClicked(), options);
  }

  /**
   * Subscription State Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20State%20Viewed)
   *
   * **瀏覽「訂閱管理」頁**
   *
   * @param options Amplitude event options.
   */
  subscriptionStateViewed(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionStateViewed(), options);
  }

  /**
   * Subscription Success Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20Success%20Viewed)
   *
   * **跳出「訂閱成功」Dialog**
   *
   * @param options Amplitude event options.
   */
  subscriptionSuccessViewed(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSuccessViewed(), options);
  }

  /**
   * Title Published
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Title%20Published)
   *
   * **作品上架**
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  titlePublished(
    properties: TitlePublishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TitlePublished(properties), options);
  }

  /**
   * Verified Badge Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Verified%20Badge%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 認證作家申請**
   *
   * @param options Amplitude event options.
   */
  verifiedBadgeButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new VerifiedBadgeButtonClicked(), options);
  }

  /**
   * Withdrawal Record Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Withdrawal%20Record%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 收益請款 - 請款紀錄**
   *
   * @param options Amplitude event options.
   */
  withdrawalRecordButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new WithdrawalRecordButtonClicked(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
