const queryKeys = {
  ME: 'me',
  MY_OVERVIEW: 'my-overview',
  MY_TITLE_STATS: 'my-title-stats',
  TITLE: 'title',
  MY_STATISTICS_REPORT: 'my-statistics-report',
  CHAPTER_READING_TICKET_SETTINGS: 'chapter-reading-ticket-settings',
  WITHDRAWAL_INFORMATION: 'withdrawal-information',
  REVENUE_REPORT: 'revenue-report',
  WITHDRAWAL_RECORDS: 'withdrawal-records',
  GENRES: 'genres',
  MONTHLY_STATS_TOTAL: 'monthly-stats-total',
  MONTHLY_STATS_TITLES: 'monthly-stats-titles',
  TOTAL_STATS_TOTAL_OVERALLS: 'total-stats-total-overalls',
  TOTAL_STATS_TITLE_OVERALLS: 'total-stats-title-overalls',
  VERIFIED_AUTHOR_STATUS: 'verified-author-status',
}

export default queryKeys
