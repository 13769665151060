import { pxToRem } from '@/utils'

import { gray } from './gray'
import { typography } from './typography'

import type { Theme } from 'theme-ui'
const makeTheme = <T extends Theme>(t: T) => t

const theme = makeTheme({
  config: {
    useLocalStorage: false,
  },
  fonts: {
    body: '"Noto Sans TC", "Noto Sans SC", "Noto Sans JP", "GenYoGothic", sans-serif',
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
  fontSizes: [
    pxToRem(12), // 0
    pxToRem(14), // 1
    pxToRem(16), // 2
    pxToRem(20), // 3
    pxToRem(24), // 4
    pxToRem(28), // 5
    pxToRem(32), // 6
    pxToRem(48), // 7
  ],
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
    body: '1.625',
    heading: '1.25',
  },
  space: [
    pxToRem(0), // 0
    pxToRem(4), // 1
    pxToRem(8), // 2
    pxToRem(16), // 3
    pxToRem(32), // 4
    pxToRem(64), // 5
  ],
  breakpoints: ['640px', '768px', '1024px', '1280px'],
  sizes: {
    full: '100%',
    appBar: pxToRem(64),
    smallIcon: pxToRem(16),
    touchArea: pxToRem(48),
  },
  zIndices: {
    dropdownMenu: 55,
    tabBar: 10,
    toast: 60,
    appBar: 30,
    bottomBar: 30,
    editorToolbar: 30,
    dialog: 50,
    sideMenu: 40,
  },
  initialColorModeName: 'default',
  colors: {
    text: '#1A202C',
    background: '#FFFFFF',
    primary: '#FF8674',
    error: '#E65442',
    success: '#35BCBC',
    gray,
    modes: {
      _dark: {
        background: '#000',
        gray: [...gray].reverse(),
      },
    },
  },
  shadows: {
    default: `0px 0px ${pxToRem(32)} 0px rgba(203, 213, 224, 0.3)`,
  },
  radii: {
    default: '0.25rem', // 4 px
    middle: '0.5rem', // 8 px:
    extra: '50rem', // 800 px
    round: '50%',
  },
  styles: {
    root: {
      fontFamily: 'body',
      overflowX: 'hidden',
    },
    img: {
      maxWidth: 'full',
      width: 'full',
      height: 'auto',
    },
    hr: {
      width: 'full',
      height: 1,
      color: 'gray.5',
      m: 0,
    },
    spinner: {
      color: 'gray.3',
      strokeWidth: pxToRem(2),
    },
  },
  typography,
  singleLineTruncation: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
  images: {
    avatar: {
      width: pxToRem(40),
      height: pxToRem(40),
      borderRadius: 'round',
    },
  },
  layout: {
    container: {
      maxWidth: pxToRem(984),
      mx: 'auto',
    },
    setting: {
      maxWidth: pxToRem(512),
      mx: 'auto',
    },
  },
  buttons: {
    primary: {
      ...typography.callout[14],
      color: 'white',
      bg: 'primary',
      height: pxToRem(48),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 2,
      userSelect: 'none',
      transition: 'background-color .3s ease-out',
      '&:hover': { backgroundColor: 'gray.8' },
      '&:disabled': { backgroundColor: 'gray.5', cursor: 'not-allowed' },
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: pxToRem(48),
      height: pxToRem(48),
      '&:disabled': { opacity: 0.38, cursor: 'not-allowed' },
    },
    small_icon: {
      fontSize: 4,
      color: 'gray.8',
      strokeWidth: '1.5',
      width: 'smallIcon',
      height: 'smallIcon',
    },
    outlined: {
      ...typography.callout[14],
      color: 'primary',
      backgroundColor: 'transparent',
      height: pxToRem(48),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 2,
      userSelect: 'none',
      transition: 'background-color .3s ease-out',
      borderWidth: pxToRem(1),
      borderStyle: 'solid',
      borderRadius: 'extra',
      borderColor: 'primary',
      '&:disabled': { opacity: 0.38, cursor: 'not-allowed' },
    },
    smallFilled: {
      ...typography.body[14],
      color: 'gray.0',
      bg: 'primary',
      height: pxToRem(36),
      maxWidth: pxToRem(128),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 2,
      userSelect: 'none',
      transition: 'background-color .3s ease-out',
      borderRadius: '24px',
      '&:hover': { backgroundColor: 'gray.8' },
      '&:disabled': { color: 'primary', backgroundColor: 'gray.1', cursor: 'not-allowed' },
    },
    text: {
      ...typography.body[14],
      px: 3,
      color: 'gray.5',
      backgroundColor: 'transparent',
      height: pxToRem(48),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      userSelect: 'none',
      transition: 'color .3s ease-out',
      '&:hover': { color: 'gray.8' },
      '&:disabled': { color: 'gray.5', cursor: 'not-allowed' },
    },
  },
  messages: {
    basic: {
      ...typography.body[14],
      display: 'flex',
      alignItems: 'center',
      gap: 3,
      p: 3,
      borderRadius: 'default',
      boxShadow: 'default',
      borderLeft: 'none',
      backgroundColor: 'gray.0',
    },
    info: {
      variant: 'messages.basic',
      backgroundColor: 'gray.1',
    },
    error: {
      variant: 'messages.basic',
      backgroundColor: '#FFF4F2',
    },
    success: {
      variant: 'messages.basic',
      backgroundColor: '#F0FCFA',
    },
  },
  appBar: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flex: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 3,
    width: 'full',
    height: 'appBar',
    zIndex: 'appBar',
    backgroundColor: 'background',
  },
  superCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  absoluteCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  fixedCenter: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  dialog: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: 'full',
    height: 'full',
    zIndex: 'dialog',
  },
  dimmer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 'full',
    height: 'full',
    backgroundColor: 'rgba(26,32,44, 0.7)',
    zIndex: -1,
  },
  touchArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'touchArea',
    height: 'touchArea',
    cursor: 'pointer',
  },
  sideMenu: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'full',
    height: 'full',
    zIndex: 'sideMenu',
  },
})

export default theme
// Theme的type
export type ThemeType = typeof theme
