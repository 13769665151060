import endpoints from '@/constants/endpoints'
import axios from 'axios'

export const chapterSavedTrackEvent = async (chapterId: number) => {
  const res = await axios({
    method: 'POST',
    url: endpoints.CHAPTER_SAVED_TRACK_EVENT,
    data: { chapterId },
  })
  return res.data
}
