const endpoints = {
  // Members
  ME: '/v2/members/me',
  AGREE_CREATOR_TERMS: '/v2/members/me/agreeCreatorTerms',
  VERIFIED_AUTHOR: '/v2/members/me/verifiedAuthor',

  SIGN_OUT: '/v2/signOut',
  MY_OVERVIEW: '/v2/creator/myOverview',
  MY_TITLE_STATS: '/v2/creator/myTitleStats',
  TITLE: '/v2/creator/titles',
  MY_STATISTICS_REPORT: '/v2/creator/myStatisticsReport',
  CHAPTER_READING_TICKET_SETTINGS: '/v2/creator/chapterReadingTicketSettings',
  GENRES: '/v2/genres',

  // Revenue
  WITHDRAWABLE: '/v2/creator/withdrawable',
  WITHDRAWALS: '/v2/creator/withdrawals',
  REVENUE_REPORT: '/v2/creator/revenueReport',

  // Data Center
  MONTHLY_STATS_TOTAL: '/v2/creator/monthlyStats/total',
  MONTHLY_STATS_TITLES: '/v2/creator/monthlyStats/titles',
  TOTAL_STATS_TOTAL_OVERALLS: '/v2/creator/totalStats/totalOveralls',
  TOTAL_STATS_TITLE_OVERALLS: '/v2/creator/totalStats/titleOveralls',

  // Amplitude
  TRACK_EVENT_ONLY: '/v2/comment/create/trackEventOnly',
  CHAPTER_SAVED_TRACK_EVENT: '/v2/chapter/saved/trackEventOnly',

  // 投稿
  TARGET_AUDIENCE: '/v2/creator/targetAudience',
}

export default endpoints
