export const routes = {
  HOME: '/',
  MY_BOOKS: '/book/all',
  BOOK_SETTING: '/book/setting',
  BOOK_UNITICKET: '/book/uniticket',
  BOOK: '/book',
  CHAPTER_SETTING: '/chapter/setting',
  CHAPTER_SORTING: '/chapter/sorting',
  EDITOR: '/chapter/editor',
  NOT_FOUND: '/404/',
  VERIFIED_BADGE: '/verified-badge',
  VERIFIED_BADGE_FORM: '/verified-badge/form',
  TERMS: '/terms',
  REVENUE_DETAIL: '/revenue/detail',
  REVENUE_WITHDRAWAL_RECORD: '/revenue/withdrawal-record',
  REVENUE_REPORT: '/revenue/report',
  WITHDRAW_FORM: '/withdraw/form',
  DATA_CENTER: '/data-center',
  BOOK_DATA: '/data-center/book',
  ANNOUNCEMENT: '/announce',
  CONTEST_LIST: '/contest/all',
  CONTEST: '/contest',
  COMMENTS: '/comments',
}
